import React from 'react';
import Logo from '../../elements/logo/Logo';
import StickyHeader from './StickyHeader';
import MobileMenu from './MobileMenu';


const SplashHeader = () => {

    const MobileMenuHandler = () => {
        document.querySelector('.mobilemenu-popup').classList.toggle("show");
        document.querySelector('body').classList.toggle("mobilemenu-show");

        var elements = document.querySelectorAll('.mobilemenu-popup .menu-item-has-children > a');
    
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.axil-submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
    }

    const sticky = StickyHeader(100);

    return (
        <header className="header axil-header header-style-1 splash-header-style">
            <div className={`axil-mainmenu ${sticky ? "axil-sticky" : ""}`}>
                <div className="container">
                <div className="header-navbar">
                    <div className="header-logo">
                        <Logo limage="/images/logo.svg"
                            dimage="/images/logo-3.svg"
                            simage="/images/logo-2.svg"
                        />
                    </div>
                    <div className="header-main-nav">
                    {/* Start Mainmanu Nav */}
                    <nav className="mainmenu-nav">
                        <ul className="mainmenu">
                            <li><a href="/">Home</a></li>
                            <li><a href="/service-details/website-development">Website Development</a></li>
                            <li><a href="/contact">Contact</a></li>
                            <li><a href="/pricing">Pricing</a></li>
                        </ul>
                    </nav>
                    {/* End Mainmanu Nav */}
                    </div>
                    <div className="header-action">
                        <ul className="list-unstyled">
                            <li className="buy-btn"><a href="/contact" className="axil-btn btn-fill-primary">Contact</a></li>
                            <li className="mobile-menu-btn sidemenu-btn d-lg-none d-block">
                                <button className="btn-wrap" onClick={MobileMenuHandler}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
                </div>
            </div>
            <MobileMenu MobileHandler={MobileMenuHandler}/>
        </header>

    )
}

export default SplashHeader;