import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import Header from '../common/header/SplashHeader';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';


const PrivacyPolicy = () => {

    return (
        <>
            <SEO title="Privacy Policy" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <Header />
                <BreadCrumbOne 
                title="Privacy Policy"
                page="Privacy Policy"
                />
                <div className="section-padding privacy-policy-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="privacy-policy-content">
                                <div className="section-title">
                                    <h5 className="title">This Privacy policy was published on October 10th, 2024.</h5>
                                </div>
                                <h3>Commitment to privacy</h3>
                                <p>Privacy is very important to Pixeltide. We are required to comply with the Federal Privacy Act 1988 and the Australian Privacy Principles, and therefore our privacy policy applies to any personal information we collect, use or disclose, including the personal information of our employees and contractors.</p>

                                <h3>How and why we collect personal information</h3>
                                <p>We collect personal information in order to provide various services to our clients. With consent, we may also use personal information for related purposes including providing clients with updates on our products and services.</p>

                                <h3>How we use and disclose personal information</h3>
                                <p>We do not use or disclose personal information for any purpose that is unrelated to the services we provide and that you would not reasonably expect (except with client consent). We have a duty to maintain the confidentiality of our clients’ affairs, including personal information. Our duty of confidentiality to our clients applies, except where disclosure of personal information is consented to by the client or is compelled by law.</p>

                                <p>Client personal information may be disclosed to approved third parties who are also required to comply with the Australian Privacy Principles.</p>

                                <h3>Security of your personal information</h3>
                                <p>
                                We endeavour to protect any personal information that we hold from misuse and/or loss, and to protect it from unauthorised access, modification and/or disclosure.
                                </p>
                                <h3>Contact us</h3>
                                <p>If you want to gain access to your personal information, correct or update your personal details, register a complaint about a breach of your privacy, or you have any other query relating to our privacy policy, please contact us.                                </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <CtaLayoutOne />
            <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default PrivacyPolicy;